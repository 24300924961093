.auth__form-container {
    justify-content: center;
    width: 100%;
    display: flex;
}
.auth__form {
    padding-top: 60px;
    width: 60%;
    color: white;
}
.login_logout {
    height: 24px;
    height: 24px;
    margin: 0; 
    background-color: #0f59c7;
    border-radius: 4px;
    border-width: 1px;
    color: white;
    outline: none;
    font-family: 'Muli', sans-serif;
    &:hover {
      filter: brightness(115%);
    }
      &.disabled{
        filter: brightness(80%);
      }
    &:active {
      background-color: #136cf1;
      transform: translateY(0.5px);
    }
    @media only screen and (max-width: 480px) {
      margin: 0;
      height: 30px;
      font-size: 16px;
    }
  }

  h6 {
    padding: 0;
    margin: 5px 0 10px 0 ;
    font-family: 'Muli', sans-serif;
    font-size: 16px;
    color: red
  }