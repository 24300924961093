$base-color: #282c34;

.navbar-container {
    height: 50px;
    background-color: $base-color;
    position: fixed;
    display: flex;
    top: 0;
    width: 100%;
    z-index: 1000;
        @media only screen and (max-width: 566px) {
          flex-direction: column;
          justify-content: center;
        }
  
      #main-logo {
        position: relative;
        margin-right: 20px;
        width: 50%;
        max-width: 250px;
        left: 1rem;
        top: 0.7rem;

        @media only screen and (max-width: 566px) {
          top: 0.1rem;
          left: 0;
          // left: 25%;
        }
      }

      .hamburger-menu {
        display: none;

        @media only screen and (max-width: 566px) {
          display: flex;
          position: absolute;
          left: 10px;
          img {
            width: 25px;
          }
          
        }
      }

      .login-menu {
        display: none;
        position: absolute;
        right: 10px;
        top: 12px;
        img {
          width: 25px;
          height: 26.16px;
        }
        
        &.logged-in {
          display: flex;
        }

        @media only screen and (max-width: 566px) {
          display: flex;
          position: absolute;
          // &.logged-in {
          //   display: none;
          //   overflow: hidden;

            img {
              // display: none;
            }
          // }
        }
      }
      
      .navbar-account-details{
        position: absolute;
        right: 1rem;
        bottom: 0;
        display: flex;
        @media only screen and (max-width: 566px) {
          width: fit-content;
          text-align: center;
          margin: 4px 0 0 0;
          right: 40px;
          font-size: 13px;
          top: 0;
        }
        .nav-username {
          position: relative;
          top: 1px;
          color: white;
          margin-right: 30px;
          text-align: right;
           @media only screen and (max-width: 566px) {
            margin-right: 0;
            padding: 0;
            width: fit-content;
            justify-content: right;
          }
        }
      }
            

      .login-dropdown {
        background-color: $base-color;
        max-height: 50px;
        width: 400px;
        position: absolute;
        top: -0.5px;
        right: 10px;
        overflow: hidden;
        z-index: 9999;

        @media only screen and (max-width: 566px) {
          display: none;
        }
      }

      .login_logout {
        position: relative;
        z-index: 10000;
        top: 0.9rem;
        height: 24px;
        height: 24px;
        margin: 0  1rem; 
        background-color: #0f59c7;
        border-radius: 4px;
        border: none;
        color: white;
        outline: none;
        margin-left: 0;
        font-family: 'Muli', sans-serif;
        &:hover {
          filter: brightness(115%);
        }
          &.disabled{
            filter: brightness(70%);
          }
        &:active {
          background-color: #136cf1;
          transform: translateY(0.5px);
        }
        @media only screen and (max-width: 566px) {
          display: none;
        }
      }
      .auth__form {
        padding-top: 0;
        z-index: 9999;
        width: 100%;

        .auth__form-components {
          display: flex;
          flex-direction: row;
          a {
           margin-top: 14.5px;
           padding: 0; 
           height: fit-content;
           top: 5px;
           .register {
             position: relative;
             top: 1px;
             right: -8px;
           }
          }
        }
        form {
          width: 100%;
          display: flex;
          flex-direction: row;
          .input input {
            width: 80%;
            font-size: 12px;
            transform: translateY(-1.5px)
          }
          &.error {
            position: relative;
            animation: shake .1s linear;
            animation-iteration-count: 3;
            .login_logout, input, .untouched {
              background-color: rgba(255, 44, 44, 0.329);
            }
          }
          @keyframes shake {
              0% { left: 0 }
              100% { right: -5px; }
          }
        }
      }
  }