$base-color: #ffffff;
$secondary-color: #323741;

span {
  display: inline-flex;
  h2, h3 {
    text-align: left;
  }
  
}
.app-main-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 0;
  }
}
.app-container {
  position: relative;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;

  .main-container {
    position: relative;
    width: 100%;
    background-color: $base-color;
    min-width: 300px;
    min-height: 100vh;
    justify-content: center;
    z-index: 90;

    .secondary-nav {
      position: fixed;
      top: 0;
      width: 100%;
      text-align: right;
      padding: 0;
      top: 50px;
      height: 40px;
      z-index: 100;
      background-color: #ED5656;
  }

  .postcode-information {
    color: $secondary-color;
    position: relative;
    top: 85px;
    width: 100%;
    justify-content: center;
    display: flex;
    margin: 0;
    padding: 0;
    background-color: rgb(211, 211, 211);

    @media only screen and (max-width: 480px) {
      top: 80px;
    }
  }
  
    .outer-card-container {
      display: flex;
      margin-top: 20px;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      position: relative;
      height: fit-content;
      width: 100vw;
      min-width: 300px;
    }
  
    .card-container {
      margin-top: 70px;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      position: relative;
      height: fit-content;
      width: fit-content;
      max-width: 98%;
      margin-right: auto;
      margin-left: auto;
  
      @media only screen and (max-width: 974px) {
        max-width: 95%;
        justify-content: center;
      }

      @media only screen and (max-width: 480px) {
        margin-top: 60px;

      }
  
      &::after {
        content: "";
        flex: auto;
        width: 20%;
      }
    }
  }
  .wall-card-none-found {
    margin-top: 10%;
  }
  
  
  /* @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */
}
